import useHasScrolled from '@/shared/hooks/use-has-scrolled';
import Link from 'next/link';
import Image from 'next/image';
import React from 'react';
import { AppLink } from '@/shared/ui/AppLink';
import { useSession } from 'next-auth/react';
import { UserMenu } from '@/widgets/UserMenu';
import classNames from 'classnames';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import { ProductsMenu } from '@/widgets/ProductsMenu';
import { BurgerMenu } from '@/widgets/BurgerMenu';

interface HeaderProps {
    className?: string;
}

const Header = (props: HeaderProps) => {
    const hasScrolled = useHasScrolled();
    const { data: session, status } = useSession();
    const isAuthenticated = session && status === 'authenticated';

    return (
        <header
            className={classNames(
                'flex items-center justify-center',
                `fixed top-0 w-full z-30 transition-all`,
                {
                    'border-b border-gray-200 bg-white/50 backdrop-blur-lg':
                        hasScrolled
                },
                { 'bg-white/0': !hasScrolled }
            )}
        >
            <div
                className={classNames(
                    'w-full mx-5 h-16 max-w-screen-xl grid grid-cols-2 lg:grid-cols-[0.5fr_auto_0.5fr] items-center gap-x-4'
                )}
            >
                <Link
                    href={composeLink({})}
                    className="mr-auto grow basis-0 flex items-center font-display text-xl sm:text-2xl"
                >
                    <Image
                        src="/logo.svg"
                        alt="AllDataFeeds logo"
                        width="40"
                        height="40"
                        className="mr-2 rounded-sm"
                    ></Image>
                    <p className="font-medium">AllDataFeeds.com</p>
                </Link>
                <div className="max-lg:hidden">
                    <ProductsMenu />
                </div>
                <div className="ml-auto grow basis-0 flex items-center gap-4 sm:gap-6">
                    {!isAuthenticated ? (
                        <>
                            <AppLink
                                className="max-lg:hidden"
                                href={composeLink({
                                    path: PATHS.LOGIN
                                })}
                            >
                                Log In
                            </AppLink>
                            <AppLink
                                href={composeLink({
                                    path: PATHS.REGISTER
                                })}
                                type="button"
                            >
                                Sign Up
                            </AppLink>
                        </>
                    ) : (
                        <UserMenu />
                    )}
                    <BurgerMenu className="lg:hidden" />
                </div>
            </div>
        </header>
    );
};

export default Header;
