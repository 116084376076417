import Link from 'next/link';
import { AppLink } from '@/shared/ui/AppLink';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';

const Footer = () => {
    return (
        <footer className="flex items-center justify-center w-full border-t border-gray-200 bg-white z-[9999]">
            <div className="w-full sm:h-16 mx-5 max-w-screen-xl flex items-center justify-between max-sm:flex-col max-sm:py-4 max-sm:text-center">
                <p className="text-gray-500">
                    © 2023 <Link href={composeLink({})}>AllDataFeeds.com</Link>.
                    All rights reserved.
                </p>
                <div className="flex gap-4">
                    <AppLink
                        href={composeLink({
                            path: PATHS.PRIVACY_POLICY
                        })}
                        className="text-gray-500 font-normal"
                        size="lg"
                    >
                        Privacy Policy
                    </AppLink>
                    <AppLink
                        href={composeLink({
                            path: PATHS.TERMS_OF_SERVICE
                        })}
                        className="text-gray-500 font-normal"
                        size="lg"
                    >
                        Terms of Service
                    </AppLink>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
