import Link from 'next/link';
import React from 'react';
import classNames from 'classnames';
import { Button, ButtonSize } from '@/shared/ui/Button';

interface AppLinkProps extends React.HTMLAttributes<HTMLAnchorElement> {
    className?: string;
    href: string;
    type?: 'button' | 'link';
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
    target?: string;
    legacyBehavior?: boolean;
    children: React.ReactNode;
}

const AppLink = (props: AppLinkProps) => {
    const {
        className,
        href,
        type = 'link',
        size = 'md',
        target = '_self',
        legacyBehavior = false,
        children,
        ...otherProps
    } = props;
    const sizeClasses = classNames({
        'text-sm': size === 'sm',
        'text-base': size === 'md',
        'text-lg': size === 'xl',
        'text-xl': size === '2xl'
    });

    if (type === 'button') {
        return (
            <Link
                className={className}
                href={href}
                target={target}
                {...otherProps}
            >
                <Button size={size as ButtonSize}>{children}</Button>
            </Link>
        );
    }

    return (
        <Link
            legacyBehavior={legacyBehavior}
            href={href}
            className={classNames(
                'block font-medium text-black hover:underline',
                sizeClasses,
                className
            )}
            target={target}
            {...otherProps}
        >
            {children}
        </Link>
    );
};

export default AppLink;
