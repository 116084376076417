import { signOut, useSession } from 'next-auth/react';
import React from 'react';
import { Button, ButtonSize, ButtonTheme } from '@/shared/ui/Button';
import { useUserSessionEmail } from '@/shared/hooks/use-user-session-email';
import { useUserSessionStripeId } from '@/shared/hooks/use-user-session-stripe-id';
import * as Avatar from '@radix-ui/react-avatar';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

const UserMenu = () => {
    const { data: session } = useSession();
    const userAvatarLiteral = session?.user?.email?.charAt(0).toUpperCase();
    const userEmail = useUserSessionEmail();
    const userStripeCustomerId = useUserSessionStripeId();
    const customerPortalUrl = `/api/stripe/portal?customerId=${userStripeCustomerId}`;

    return (
        <NavigationMenu.Root>
            <NavigationMenu.List>
                <NavigationMenu.Item className="relative">
                    <NavigationMenu.Trigger>
                        <Avatar.Root className="AvatarRoot relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-black rounded-full">
                            <Avatar.Fallback className="AvatarFallback font-medium text-white uppercase">
                                {userAvatarLiteral}
                            </Avatar.Fallback>
                        </Avatar.Root>
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="z-10 bg-white absolute right-0 divide-y divide-gray-100 rounded-lg shadow max-w-64 block mt-1">
                        <div className="px-4 py-3 text-sm text-gray-900">
                            <div className="font-medium truncate">
                                {userEmail}
                            </div>
                        </div>
                        <ul
                            className="py-2 text-sm text-gray-7000"
                            aria-labelledby="avatarButton"
                        >
                            <li>
                                <a
                                    href={customerPortalUrl}
                                    className="block px-4 py-2 hover:bg-gray-100"
                                    target={'_blank'}
                                >
                                    Customer portal
                                </a>
                            </li>
                        </ul>
                        <div className="px-4 py-2">
                            <Button
                                theme={ButtonTheme.Outline}
                                className="w-full"
                                size={ButtonSize.Small}
                                onClick={signOut}
                            >
                                Sign out
                            </Button>
                        </div>
                    </NavigationMenu.Content>
                </NavigationMenu.Item>
            </NavigationMenu.List>
        </NavigationMenu.Root>
    );
};

export default UserMenu;
