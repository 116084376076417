import React, { useEffect } from 'react';
import classNames from 'classnames';
import useScrollBlock from '@/shared/hooks/use-scroll-block';
import Link from 'next/link';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import Image from 'next/image';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import Portal from '@/shared/ui/Portal';
import { className } from 'postcss-selector-parser';

interface BurgerMenuProps {
    className?: string;
}

interface BurgerProps {
    className?: string;
    open: boolean;
    toggleMenu: () => void;
}

const Burger = (props: BurgerProps) => {
    const { className, open, toggleMenu } = props;
    return (
        <div
            className={classNames(
                'tham-e-squeeze tham tham-w-6',
                'z-[100] cursor-pointer',
                {
                    'tham-active': open
                },
                className
            )}
            onClick={toggleMenu}
        >
            <div className="tham-box">
                <div className="tham-inner" />
            </div>
        </div>
    );
};

const BurgerMenu = (props: BurgerMenuProps) => {
    const { className } = props;
    const [open, setOpen] = React.useState(false);
    const [blockScroll, allowScroll] = useScrollBlock();
    const { data: session, status } = useSession();
    const isAuthenticated = session && status === 'authenticated';
    const { pathname } = useRouter();

    useEffect(() => {
        setOpen(false);
    }, [pathname]);

    React.useEffect(() => {
        if (open) {
            blockScroll();
        } else {
            allowScroll();
        }
    }, [allowScroll, blockScroll, open]);

    const toggleMenu = () => {
        setOpen(!open);
    };

    return (
        <div className={className}>
            <Burger open={open} toggleMenu={toggleMenu} />
            <Portal>
                <div
                    className={classNames(
                        'fixed top-0 left-0 w-full h-full bg-white/50 backdrop-blur-lg',
                        'flex flex-col items-center justify-center',
                        'transition-all duration-300',
                        'z-50',
                        {
                            'opacity-100 pointer-events-auto': open
                        },
                        {
                            'opacity-0 pointer-events-none': !open
                        }
                    )}
                >
                    <Burger
                        className="absolute top-[24px] right-[20px]"
                        open={open}
                        toggleMenu={toggleMenu}
                    />
                    <div className="flex flex-col items-center justify-center gap-4">
                        <div className="text-xl font-medium">
                            <Image
                                src="/logo.svg"
                                alt="AllDataFeeds logo"
                                width="40"
                                height="40"
                                className="mx-auto mb-4"
                            ></Image>
                            <Link href={composeLink({})}>AllDataFeeds.com</Link>
                        </div>
                        <hr className="w-full border-gray-200" />
                        <div className="text-xl font-medium">
                            <Link href={composeLink({ path: PATHS.ABOUT_US })}>
                                About Us
                            </Link>
                        </div>
                        <div className="text-xl font-medium">
                            <Link
                                href={composeLink({
                                    path: PATHS.CONTACT_SALES
                                })}
                            >
                                Contact Sales
                            </Link>
                        </div>
                        <hr className="w-full border-gray-200" />
                        <div className="text-xl font-medium">
                            <Link
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE
                                })}
                            >
                                Threat Intelligence Data Feeds
                            </Link>
                        </div>
                        <div className="text-xl font-medium">
                            <Link
                                href={composeLink({
                                    subdomain: SUBDOMAINS.MAC_ADDRESS
                                })}
                            >
                                MAC Address Data Feed
                            </Link>
                        </div>
                        {!isAuthenticated && (
                            <>
                                <hr className="w-full border-gray-200" />
                                <div className="text-xl font-medium">
                                    <Link
                                        href={composeLink({
                                            path: PATHS.LOGIN
                                        })}
                                    >
                                        Login
                                    </Link>
                                </div>
                                <div className="text-xl font-medium">
                                    <Link
                                        href={composeLink({
                                            path: PATHS.REGISTER
                                        })}
                                    >
                                        Sign Up
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Portal>
        </div>
    );
};

export default BurgerMenu;
