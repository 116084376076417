import Head from 'next/head';
import { ProductLogos } from '@/shared/types/products';
import { useRouter } from 'next/router';

interface AppHeadProps {
    title?: string;
    description?: string;
    noIndex?: boolean;
    productLogo?: ProductLogos;
}

const AppHead = (props: AppHeadProps) => {
    const router = useRouter();
    const { title, description, noIndex, productLogo } = props;
    const logo = productLogo as string;
    const currentUrl = router.asPath;

    return (
        <Head>
            {noIndex && <meta name="robots" content="noindex" />}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={currentUrl} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={logo} />
            <meta property="og:locale" content="en_GB" />

            <meta itemProp="name" content={title} />
            <meta itemProp="description" content={description} />
            <meta itemProp="image" content={logo} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={logo} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <link rel="icon" href="/favicon.ico" />
            <link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
            />
            <link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#5bbad5"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </Head>
    );
};

export default AppHead;
