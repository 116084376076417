// @ts-nocheck
import React from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { composeLink } from '@/shared/lib/links';
import { PATHS, SUBDOMAINS } from '@/shared/lib/constants';
import Link from 'next/link';
import Image from 'next/image';

const ListItem = React.forwardRef(
    (
        { className, children, title, href = '#', logo, ...props },
        forwardedRef
    ) => (
        <li>
            <NavigationMenu.Link asChild>
                <Link
                    href={href}
                    className={classNames(
                        'hover:bg-gray-100 block select-none rounded-lg text-sm p-3 leading-none no-underline outline-none transition-colors',
                        {
                            'pointer-events-none opacity-30': href === '#'
                        },
                        className
                    )}
                    {...props}
                    ref={forwardedRef}
                >
                    <div className="flex items-center gap-4">
                        <Image src={logo} alt={title} width={30} height={30} />
                        <div className="font-medium">{title}</div>
                    </div>
                </Link>
            </NavigationMenu.Link>
        </li>
    )
);

const ProductsMenu = () => {
    return (
        <NavigationMenu.Root className="relative z-[1] flex flex-1 justify-center">
            <NavigationMenu.List className="center shadow-blackA7 m-0 flex list-none rounded-lg bg-white p-1 shadow-md">
                <NavigationMenu.Item>
                    <NavigationMenu.Trigger className="group flex select-none items-center justify-between gap-1.5 rounded-md px-3 py-2 text-sm font-medium leading-none outline-none hover:bg-gray-100 transition-colors">
                        Products
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className="w-[10px] h-[10px] relative transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
                            aria-hidden
                        />
                    </NavigationMenu.Trigger>
                    <NavigationMenu.Content className="data-[motion=from-start]:animate-enterFromLeft data-[motion=from-end]:animate-enterFromRight data-[motion=to-start]:animate-exitToLeft data-[motion=to-end]:animate-exitToRight absolute top-0 left-0 w-full sm:w-auto rounded-xl">
                        <ul className="one m-0 grid list-none gap-x-[10px] p-[22px] sm:w-[600px] sm:grid-cols-[0.75fr_1fr]">
                            <li className="row-span-4 grid">
                                <NavigationMenu.Link asChild>
                                    <Link
                                        className="bg-gradient-to-br from-indigo-50 via-white to-cyan-100 flex h-full w-full select-none flex-col gap-2 justify-end rounded-lg p-[25px] no-underline outline-none transition-shadow hover:shadow-lg"
                                        href={composeLink({})}
                                    >
                                        <Image
                                            src="/logo.svg"
                                            alt="AllDataFeeds logo"
                                            width="40"
                                            height="40"
                                        ></Image>
                                        <p className="font-medium">
                                            AllDataFeeds.com
                                        </p>
                                        <p className="text-sm">
                                            Stay ahead of cyber threats with our
                                            intelligence feeds
                                        </p>
                                    </Link>
                                </NavigationMenu.Link>
                            </li>

                            <ListItem
                                href={composeLink({
                                    subdomain: SUBDOMAINS.THREAT_INTELLIGENCE
                                })}
                                logo="/products/tidf.svg"
                                title="Threat Intelligence Data Feeds"
                            >
                                Get actionable insights into the latest cyber
                                threats, including malware, phishing, C2 (C&C),
                                botnets, and other malicious activity.
                            </ListItem>
                            <ListItem
                                href={composeLink({
                                    subdomain: SUBDOMAINS.MAC_ADDRESS
                                })}
                                logo="/products/mac.svg"
                                title="MAC Address Data Feed"
                            >
                                Get the most comprehensive database of
                                registered MAC address blocks, also referred to
                                as OUIs (Organizationally Unique Identifiers).
                            </ListItem>
                            <ListItem
                                logo="/products/vpn.svg"
                                title="VPN IP Ranges Data Feed"
                            >
                                Comprehensive list of IP ranges associated with
                                various VPN providers.
                            </ListItem>
                            <ListItem
                                logo="/products/passwords.svg"
                                title="Compromised Passwords Data Feed"
                            >
                                Comprehensive list of IP ranges associated with
                                various VPN providers.
                            </ListItem>
                        </ul>
                    </NavigationMenu.Content>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href={composeLink({ path: PATHS.ABOUT_US })}
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            About Us
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Item>
                    <NavigationMenu.Link asChild>
                        <Link
                            href={composeLink({ path: PATHS.CONTACT_SALES })}
                            className="block select-none rounded-md px-3 py-2 text-sm font-medium leading-none no-underline outline-none hover:bg-gray-100 transition-colors"
                        >
                            Contact Sales
                        </Link>
                    </NavigationMenu.Link>
                </NavigationMenu.Item>

                <NavigationMenu.Indicator className="data-[state=visible]:animate-fadeIn data-[state=hidden]:animate-fadeOut top-full z-[1] flex h-[10px] items-end justify-center overflow-hidden transition-[width,transform_250ms_ease]">
                    <div className="relative top-[70%] h-[10px] w-[10px] rotate-[45deg] rounded-tl-[2px] bg-white" />
                </NavigationMenu.Indicator>
            </NavigationMenu.List>

            <div className="perspective-[2000px] absolute top-full flex justify-center">
                <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-lg bg-white transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
            </div>
        </NavigationMenu.Root>
    );
};

export default ProductsMenu;
