import { ReactNode } from 'react';
import { Footer } from '@/widgets/Footer';
import { Header } from '@/widgets/Header';

export default function Layout({
    children
}: {
    meta?: {
        title?: string;
        description?: string;
        image?: string;
    };
    children: ReactNode;
}) {
    return (
        <div className="flex flex-col min-w-screen min-h-screen bg-gradient-to-br from-indigo-50 via-white to-cyan-100">
            <Header />
            <main className="flex flex-1 flex-col items-center justify-center pt-32 lg:pt-40 py-12 lg:py-28">
                {children}
            </main>
            <Footer />
        </div>
    );
}
